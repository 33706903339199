import React, { useEffect, useRef } from 'react';

import kitchen from "./glb11.glb";

const Interior = () => {
  

return (
	<div id="solution">
   <div class="homeNews" ><h1>Let Your Home Be Unique</h1><p>

Explore more than just modular design ideas with our experts.
Complete the designs with painting, flooring and other decor solutions.
Move in with ease, with our hassle-free civil work and installation services.</p><div class="flex flex-col items-center justify-center gap-3 mt-10 lg:flex-row lg:justify-start"><a href="#" class="items-center justify-center w-full px-6 py-2.5  text-center text-white duration-200 bg-black border-2 border-black rounded-full inline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none lg:w-auto focus-visible:outline-black text-sm focus-visible:ring-black">Get Quote</a><a href="#" class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600">Learn more &nbsp; →</a></div>
    </div>
  
   <section class=" mb-10 md:mb-12 xl:mb-12">

   
             


</section>
<section>
<h1 class="font-semibold text-gray-400 text-2xl md:text-3xl xl:text-3xl text-center leading-tight mt-20 xl:mt-20">Complete home interiors in easy steps with <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">SteelWits</span></h1>

 
</section>

<section class="bg-white py-10 md:py-16">

<div class="home-experience"><div class="h-years"><span class="h-year num">2</span><p>Years Of Experience</p></div><div class="h-s-project"><span class="h-sp num">14</span><p>Success Project</p></div><div class="h-a-project"><span class="h-ap num">9</span><p>Active Project</p></div><div class="h-customers"><span class="h-cust num">14</span><p>Happy Customers</p></div></div>
<div class="container max-w-screen-xl mx-auto px-4 xl:relative">


    <h1 class="font-semibold text-gray-900 text-2xl md:text-4xl text-center leading-normal mb-14">What People Say <br /> About <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">SteelWits</span> Interior Services </h1>
    <div class="flex mt-10 flex-col md:flex-row md:items-center justify-center md:space-x-8 lg:space-x-12 mb-10 md:mb-20">
        <div class="bg-gray-100 rounded-lg md:mb-0">
            <img src="assets/image/testimoni-3.png" alt="Image" class="mx-3 my-3" />
            <div class="flex items-center gap-5 mx-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>
            <p class="font-normal text-sm lg:text-md text-gray-400 mx-3 my-3">I recommend anyone to Take interior services on SteelWits. I received great customer service from the specialists who designed our home.</p>
           
            <p class="font-semibold text-gray-900 mx-8 mb-8">Govardhan Reddy</p>
        </div>
        <div class="bg-gray-100 rounded-lg">
            <img src="assets/image/testimoni-4.png" alt="Image" class="mx-3 my-3" />
            <div class="flex items-center gap-5 mx-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>

            <p class="font-normal text-sm lg:text-md text-gray-400 mx-3 my-3">SteelWits is the best ybo for our interiors world. I received great service from the SteelWits.</p>
          
            <p class="font-semibold text-gray-900 mx-8 mb-8">Vikas Interiors</p>
        </div>
        <div class="bg-gray-100 rounded-lg">
            <img src="assets/image/testimoni-4.png" alt="Image" class="mx-3 my-3" />
            <div class="flex items-center gap-5 mx-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>

            <p class="font-normal text-sm lg:text-md text-gray-400 mx-3 my-3">SteelWits is the best Interiors in the market, I received great pricing & optimistic service from the SteelWits.</p>
            <p class="font-semibold text-gray-900 mx-8 mb-8">Gopi Krishna</p>
        </div>
        <div class="bg-gray-100 rounded-lg">
            <img src="assets/image/testimoni-4.png" alt="Image" class="mx-3 my-3" />
            <div class="flex items-center gap-5 mx-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>

            <p class="font-normal text-sm lg:text-md text-gray-400 mx-3 my-3">SteelWits is the place we recommend to have multiple options and minifix installations, materials used for the projects are best in the market. <br /></p>
            <p class="font-semibold text-gray-900 mx-8 mb-8">Lalitha & Praveen </p>
        </div>

    </div>

</div> 
      {/*
<div class=" flex items-center mt-10  justify-center">
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-blue-200">
      </div>
      <div class="p-8 text-center">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Discover</h2>
        
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151156.png?ga=GA1.1.1025882285.1692510312"></img>
       
      </div>
        </div>
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-red-200">
      </div>
      <div class="p-8 text-center ">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Design</h2>
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151159.png?ga=GA1.1.1025882285.1692510312"></img>
        
      </div>
      
    </div>
    
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-yellow-200">
      </div>
      <div class="p-8 text-center ">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">
Now refer us!
Refer and earn ₹ 10,000 every time your friends try SteelWits.</h2>
      
      </div>
      
    </div>
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-purple-200">
      </div>
      <div class="p-8 text-center ">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Move-In</h2>
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151121.png?ga=GA1.1.1025882285.1692510312"></img>
        
       
      </div>
     
    </div>

  </div>
</div>*/}
</section>           
</div>

);
};

export default Interior;
