import React,{useState} from 'react';

import Popup from 'reactjs-popup';
function ProjectCard({item}) {

const [open, setOpen] = useState(false);
const closeModal = () => setOpen(false);
  return (
    <>
   
    <button type="button" className="button" onClick={() => setOpen(o => !o)}>
         <div className="img-box  lg2-w-auto mx-auto flex justify-center items-center relative overflow-hidden rounded-3xl">
              <img src={item.img} alt="" className='w-[300px]' />
              <div className="p absolute top-[-100%] left-0 h-full w-full text-center bg-[#b004b0b7] p-4 pt-20">
                <h1 className="font-bold text-3xl">{item.title}</h1>
                <p className="font-bold">{item.description}</p>
              </div>
            </div>
    </button>
    <Popup open={open} closeOnDocumentClick onClose={closeModal}>
      <div className="modal">
        <a className="close" onClick={closeModal}>
          &times;
        </a>
        <img src={item.img} alt="" className='w-[600px]' />
      </div>
    </Popup>
 
   
            
    </>
  )
}

export default ProjectCard